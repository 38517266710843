export function setupGTM() {
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}

  gtag('consent', 'default', {
    'ad_storage': 'denied',
    'analytics_storage': 'denied',
    'wait_for_update': 500
  });

  let gtmId = document.documentElement.dataset.gtmId;
  if (gtmId) {
    // Initialize dataLayer for GTM
    window.dataLayer = window.dataLayer || [];
    
    // Push DOM ready event
    document.addEventListener('DOMContentLoaded', () => {
      window.dataLayer.push({
        event: 'dom-ready'
      });
    });

    // Standard GTM initialization
    (function(w, d, s, l, i) {
      w[l].push({
        "gtm.start": new Date().getTime(),
        event: "gtm.js",
      });

      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != "dataLayer" ? "&l=" + l : "";
      j.async = true;
      j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, "script", "dataLayer", "GTM-" + gtmId);
  }
}